import React from 'react'
import Layout from '../components/layout'
import Seo from "../components/seo";

import { useStaticQuery, graphql } from "gatsby"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileImage } from '@fortawesome/free-solid-svg-icons'

const Downloadable = () => {

  const data = useStaticQuery(graphql`
    {
      allDownloadableDataJson {
        edges {
          node {
            name
            source
            url
            thumbnail
          }
        }
      }
    }
  `)

  const downloadableList = data.allDownloadableDataJson.edges;

  return (
    <Layout>
      <Seo title="Descargables" />
      <div className="page-wrapper">
        <div className="container">

          <div className="row">
            <div className="col">
              <h1 className="h3 ff-roboto fw-bold mt-4">Descargables</h1>
              <hr />

              <div className="card-list">

                {
                  downloadableList.map((file, index) => {
                    const { name, source, url, thumbnail } = file.node;
                    const thumbCard = `../../downloadable/${thumbnail}`;

                    const truncateString = (str, num) => {
                      if (str.length <= num) {
                        return str
                      }
                      return str.slice(0, num) + '...'
                    }

                    return (
                      <a href={url} target="_blank" download className="card" key={index}>
                        {
                          thumbnail !== "" ?
                            <div className="thumb">
                              <img src={thumbCard} alt={name} />
                            </div>
                            :
                            <div className="thumb noimg">
                              <FontAwesomeIcon icon={faFileImage} size="3x" />
                            </div>
                        }
                        <div className="card-body">
                          <h4 className="title ff-roboto">{truncateString(name, 28)}</h4>
                          <div className="position">{source}</div>
                        </div>
                      </a>
                    )
                  })
                }

              </div>

            </div>
          </div>

        </div>
      </div>
    </Layout>
  )
}

export default Downloadable